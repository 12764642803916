<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex>
        <page-title text="Fatture di Stock"></page-title>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <form @submit.prevent="changePage(1)">
          <v-text-field
            light
            v-model="search"
            prepend-icon="search"
            @click:prepend="getFatture"
            append-icon="cancel"
            @click:append="resetItems"
            placeholder="Cerca" />
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!fatture.length">
          Nessuna fattura trovata
        </v-alert>
      </v-flex>
      <v-flex xs12>
        <v-list class="mt-2" three-line v-if="fatture.length">
          <template v-for="(fattura, index) in fatture">
            <v-list-tile
              @click="$router.push({ name: 'fatturaStock', params: { id: fattura._id} })"
              :key="'tile-'+fattura._id">
              <v-list-tile-content>
                <v-list-tile-title>
                  {{ fattura.codice }}
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ fattura.ragione_sociale }}
                </v-list-tile-sub-title>
                <v-list-tile-sub-title>
                  <div v-if="fattura.tipo==='fattura'">Fattura del {{ fattura.mese }}/{{ fattura.anno }}</div>
                  <div v-if="fattura.tipo==='nota'">Nota di credito</div>
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text>{{ fattura.data }}</v-list-tile-action-text>
                <v-list-tile-action-text class="primary--text">{{ fattura.totale | currency(2) }}</v-list-tile-action-text>
                <v-icon v-if="fattura.bozza === 1" @click.stop="deleteFattura(fattura)" color="error">delete</v-icon>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="fattura._id" v-if="index < fatture.length - 1"></v-divider>
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
        <v-tooltip left>
          <v-speed-dial
              v-model="fab"
              :bottom="true"
              :right="true"
              slot="activator"
              :fixed="true"
            >
              <v-btn
                slot="activator"
                v-model="fab"
                color="pink darken-1"
                dark
                fab
              >
                <v-icon>add</v-icon>
                <v-icon>close</v-icon>
              </v-btn>
              <v-btn
                fab
                dark
                small
                color="green"
                @click="$router.push({ name: 'fatturaStock', params: { id: 'nota' } })"
              >
                N
              </v-btn>
              <v-btn
                fab
                dark
                small
                color="indigo"
                @click="$router.push({ name: 'fatturaStock', params: { id: 'fattura' } })"
              >
                F
              </v-btn>
            </v-speed-dial>
          <span>Nuovo fattura / nota di credito</span>
        </v-tooltip>
      </v-flex>
    </v-layout>
    <modal-confirm ref="confirm" />
  </v-container>
  </template>
<script>
import modalConfirm from '@/components/common/ModalConfirm'
import PageTitle from '@/components/common/PageTitle'
import { $EventBus } from '@/eventBus'
export default {
  data () {
    return {
      fattura: {},
      fatture: [],
      selected: [],
      search: null,
      pages: 0,
      page: 1,
      paginate: 20,
      fab: false
    }
  },
  components: {
    PageTitle,
    'modal-confirm': modalConfirm
  },
  methods: {
    getFatture () {
      this.$plsqlReadAll('fattura_stock', {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      })
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.fatture = response.data.items
            this.pages = response.data.pages
          }
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura fatture' })
        })
    },
    deleteFattura (f) {
      this.fattura = f
      this.$refs.confirm.show({
        modalTitle: 'Sicuro?',
        modalText: 'Stai per cancellare la fattura <strong>' + f.codice + '</strong>.<br/>Confermi?',
        modalButtonOk: 'Elimina',
        modalFunctionOk: this.remove
      })
    },
    remove (f) {
      this.$plsqlDelete('fattura_stock', this.fattura._id)
        .then(response => {
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Fattura eliminata'
          })
          this.$refs.confirm.hide()
          this.getFatture()
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          this.$refs.confirm.hide()
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getFatture()
    }
  },
  mounted () {
    this.getFatture()
  }
}
</script>
